class Store {
  //global setting for enabling and disabling the shop because of Coroni
  enableShop = true;
  disableShopInfo = "";

  //local store
  productsData = null;
  porto1Shirt = 0;
  productsDataShort = [{},{},{},{},{},{}];
  selectedProductIndex = null;
  selectedNewsIndex = null;
  sessionStart = 0;
  dataLoaded = false;
  contentData = null;
  cityGroups = null;
  news = null;
  newsShort = [{},{},{},{}];
  projects = null;
  selectedProjectsIndex = 0;
  press = null;
  selectedPressIndex = 0;
  donations = null;
  selectedCollectorIndex = null;
  collectGroups = null;
  sellerGroups = null;
  instagramGroups = null;
  //shop global vars
  currentPageIndex = 0;
  page =  0;
  showFilter = false; 
  searchModel = {
    text: "",
    type: "",
    size: "",
    condition: "",
    price_lh: "",
    name_az: "",
    price_hl: "",
    name_za: "",
  };
  typeArray= [];
  sizeArray= [];
  conditionArray= [];
  sortArray= [];
  //popup
  firePopup = {
    title: "",
    text: "",
    type: "",
    timer: 3000
  }
  userData = {
    products: []
  };
  userDataModel = {
    sessionId: "Undefined",
    name: "",
    surname: "",
    street: "",
    additionalInformation: "",
    plz: "",
    city: "",
    email: "",
    emailConfirm: "",
    products: [],
    productsList: "",
    bill: 0,
    termsChecked: false,
    payMethod: "prePay",
    payChoices: new Array(),
    secondBandDecision: false,
    porto: 0,
  }

 getCurrentSelectedproduct(){
    var product = null;

    if(this.selectedProductIndex == null){
      const urlParams = new URLSearchParams(window.location.search);
      this.selectedProductIndex = urlParams.get('shirtId');
    }

    for(var i = 0; i < this.productsData.length; i++){
      if(parseInt(this.productsData[i].artikelnummer) == parseInt(this.selectedProductIndex)){
        product = this.productsData[i];
      }
    }
    return product;
  }

  getCurrentSelectedNews(){
    var newsArticle = null;
    if(this.selectedNewsIndex == null){
      const urlParams = new URLSearchParams(window.location.search);
      this.selectedNewsIndex = urlParams.get('newsId');
    }
    for(var i = 0; i < this.news.length; i++){
      if(this.news[i].id == this.selectedNewsIndex){
        newsArticle = this.news[i];
      }
    }
    
    return newsArticle;
  }

  getCurrentSelectedProject(){
    var projectArticle = null;
    if(this.selectedProductIndex == null){
      const urlParams = new URLSearchParams(window.location.search);
      this.selectedProjectIndex = urlParams.get('projectId');
    }
    for(var i = 0; i < this.projects.length; i++){
      if(this.projects[i].id == this.selectedProjectIndex){
        projectArticle = this.projects[i];
      }
    }
    
    return projectArticle;
  }

  getCurrentSelectedPress(){
    var pressArticle = null;
    if(this.selectedPressIndex == null){
      const urlParams = new URLSearchParams(window.location.search);
      this.selectedPressIndex = urlParams.get('pressId');
    }
    for(var i = 0; i < this.press.length; i++){
      if(this.press[i].id == this.selectedPressIndex){
        pressArticle = this.press[i];
      }
    }
    
    return pressArticle;
  }

  getCurrentSelectedCollector(){
    var collectorArticle = null;
    if(this.selectedCollectorIndex == null){
      const urlParams = new URLSearchParams(window.location.search);
      this.selectedCollectorIndex = urlParams.get('collectorId');
    }
    

    for(var i = 0; i < this.cityGroups.length; i++){
      if(this.cityGroups[i].id == this.selectedCollectorIndex){
        collectorArticle = this.cityGroups[i];
      }
    }
    
    return collectorArticle;
  }

  resetUserData(){
    this.userData = {...this.userDataModel};
    this.userData.products = [];

    var milliseconds = new Date().getTime();
    this.sessionStart = milliseconds;
    this.userData.sessionId = this.padNumber(Math.round(Math.random()*1000))+"-"+milliseconds;
  }

  padNumber(number) {
    number = number.toString();

    while(number.length < 4) {
        number = "0" + number;
    }

    return number;
  }

  formatDate(date){
    var d = new Date(date);
      var month = parseInt(d.getMonth())+1;
      var dateFullString = d.getDate()+"/"+month+"/"+d.getFullYear();
      return dateFullString;
  }
}
export default new Store();